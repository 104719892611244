@import url("https://use.typekit.net/kjs2dmo.css");

$red: #e4001c;
$green: #24862f;
$bg: white;
$sigFont: alternate-gothic-no-3-d, sans-serif;
$heroBackground: url(https://afag.imgix.net/teya-thai/tom-kha-seafood-soup.jpg?w=1400) no-repeat;

.site-content {

}
.teya {
  font-family: $sigFont;
  .cart.minimized {
    // background: url(http://mexcocinacafe.com/wp-content/uploads/2014/05/wood.jpg) no-repeat;
    padding: 0.4em 0.5em 0.1em 0.5em;
    background: $bg;
    color: black;
    background-size: cover;
    border-bottom: none;

  }
  a.checkout-button.button {
    // font-family: 'Bungee Inline', sans-serif;
    background: $red;
    // font-weight: 400;
    box-shadow: none;
    // border: 2px solid #5a7e2a;
  }

  .category-name .text {
    position: relative;
    text-transform: lowercase;
    font-family: $sigFont;
    font-weight: 400;
    font-size: 1.8em;
    background: none;
    color: black;
    z-index: 10;
    // border: 4px solid #5a7e2a;
    // border-radius: 1em;
    // color: whtie;
    // text-shadow: 0px 1px 0 black;

  }
  .category__header {
    // overflow-y: hidden;
  }
  .category-name .text::before {
    content: " ";
    display: inline-block;

    background: url(https://afag.imgix.net/teya-thai/teya-cilantro-shrimp-icon.png?w=600&bri=20) no-repeat;
    background-size: contain;
    // width: 28px;
    // height: 32px;
    // margin-right: -0.1em;
    width: 250px;
    max-width: 100%;
    height: 100px;
    z-index: -10;
    // margin-right: -0.1em;
    position: absolute;
    left: -0.5em;
    top: -1.3em;
  }
  .food-name {
    // text-transform: lowercase;
    // text-transform: capitalize;
  }
  .section-header {
    text-align: center;

    font-weight: 600;
    padding: 0.5em 0.8em;
    h2 {
      font-weight: 800;
      text-transform: uppercase;
      color: black;
    }
  }
  .splash {
    z-index: 2;
    position: absolute;
    width: 100%;
    top: 1em;
    left: 0;
    right: 0;
    margin: 0 auto;
    padding: 1em 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5em;
    h2 {
      color: white;
    }
    .catch-phrase {
      font-size: 2em;
    }
    @media screen and (max-width: 650px) {
      font-size: 1em;
    }
  }
  .landing-page-wrapper {
    position: relative;
  }
  .landing-page {
    // height: 540px;
    min-height: 400px;
    background: $heroBackground;
    background-size: cover;
    background-position: 50% 40%;
    display: flex;
    align-items: center;
    justify-content: center;
    .text {
      padding: 0 0.5em;
      font-weight: 400;
      color: white;
      font-size: 1em;
      text-align: center;
      // align-items: center;
      // justify-content: center;
      // flex-direction: column;
      // display: flex;
      // font-size: 1.5em;
      h2 {
        color: white;
      }
      .catch-phrase {
        font-size: 1.5em;
      }

      .address {
        font-size: 0.8em;
      }

      @media screen and (max-width: 650px) {
        .logo-inline {
          height: 45px;
        }
        // font-size: 1.4em;
      }
    }
  }



  .pages-nav {
    font-family: $sigFont;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 0.9em;
    // background: white;
    // color: black;
    ul {
      display: flex;
      flex-wrap: wrap;
    }
    @media screen and (max-width: 480px) {
      font-size: 4vw;
    }
  }
  $borderWidth: 4px;
  $paddingY: 0.35em;
  a.page-link {
    text-decoration: none;
    color: black;
    display: block;
    padding: $paddingY 0.3em;
    padding: $paddingY 0.3em calc(#{$paddingY} - #{$borderWidth}) 0.3em;
    // box-sizing: border-box;
    border-bottom: $borderWidth solid white;
    &:hover, &.active {
      border-bottom: $borderWidth solid black;
    }
  }

  a.order-online-link {
    text-transform: uppercase;
    // font-family: 'Helvetica', sans-serif;
    display: inline-flex;
    align-items: center;
    text-decoration: none;
    color: white;
    margin: 0.5em 0;
    padding: 0.4em 0.6em;
    border-radius: 0.2em;
    background: $red;
    position: relative;
    box-shadow: 1px 1px #770523, 3px 3px #770523, 5px 5px #770523, 7px 7px #770523;
    transition: all 0.12s ease 0s;
    &:hover {
      transform: translate(5px, 5px);
      box-shadow: none
    }
    &.disabled:hover {
      transform: none;
      box-shadow: 1px 1px #313131, 3px 3px #313131, 5px 5px #313131, 7px 7px #313131;
    }
    &.disabled {
      background: gray;
      box-shadow: 1px 1px #313131, 3px 3px #313131, 5px 5px #313131, 7px 7px #313131;
    }
  }
  .ordering-header-wrapper {
    background: url(https://afag.imgix.net/teya-thai/pad-thai-1.jpg?w=1200) no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 250px;
    color: white;
    font-size: 1.5em;
    font-weight: 900;
    .text {
      padding: 1em;
      background: rgba(0,0,0,0.5);
    }
  }
  .landing-page-wrapper {
    position: relative;
  }
  .landing-page {
    // height: 540px;
  }
  .fire-bg {
    height: 100%;
    background: url(https://d2abh92eaqfsaa.cloudfront.net/image/upload/v1552513147/danstuff/tera-wok.gif) no-repeat;
    background-size: cover;
    background-position: center;

  }
  .video-bg {
    // filter: brightness(0.5);
    position: relative;
    width: auto;
    min-width: 100%;

    height: auto;
    min-height: 100%;

    background-size: cover;
  }
  video {
    display: block;

  }
  .video-container {

    width: 100%;
    // height: 100%;
    height: 540px;
    @media screen and (max-width: 650px) {
      height: 400px;
    }
    // max-width: 960px;
    overflow: hidden;
    // position: absolute;
    top: 0;
    right: 0;
  }
  .fire-gif {
    height: 540px;
    @media screen and (max-width: 650px) {
      height: 400px;
    }
  }
}
